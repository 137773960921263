import styles from '@/assets/styles/plugins/variables.scss?inline';

export const useScssVariables = () => {

  // Extract the :export section from the SCSS file
  const regex = /:export\s*{([\s\S]+?)}/g;
  const stylesStr = typeof styles === 'string'
    ? styles
    : JSON.stringify(styles);
  const matches = regex.exec(stylesStr);
  let exported;

  if (matches) {
    exported = matches[1];
  }

  // Parse the exported variables into an object
  const scssVariablesObject: { [key: string]: string } = {};

  if (exported) {
    const varRegex = /([\w-]+)\s*[:=]\s*([^;]+);?/g;
    let matchArray;

    while ((matchArray = varRegex.exec(exported)) !== null) {
      scssVariablesObject[matchArray[1]] = matchArray[2];
    }
  }

  return scssVariablesObject;
};
